<template>
  <!-- <div class="white-background" id="about-ts-together"> -->
  <div id="about-ts-together">
    <!-- <v-container> -->
    <v-row class="text-center d-flex justify-center">
      <v-col class="mt-2 mb-10" cols="12">
        <h1 class="mt-5 mb-1 display-1 white--text">Impact partner</h1>

        <v-card class="d-flex justify-center mt-8" color="black" flat>
          <a href="https://sip.it/" target="_blank">
            <v-img
              alt="Vuetify Name"
              class="shrink"
              contain
              min-width="100"
              max-width="170"
              :src="require('@/assets/pediatria.png')"
            />
          </a>
        </v-card>
      </v-col>
    </v-row>

    <div class="py-6"></div>
  </div>
</template>

<script>
export default {
  name: "PartnersSection",
};
</script>

